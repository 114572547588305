/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import Pages from "pages/Presentation/sections/Pages";
import Testimonials from "pages/Presentation/sections/Testimonials";
import CoreOfferings from "pages/Presentation/sections/CoreOfferings";
import Download from "pages/Presentation/sections/Download";

// Presentation page components
import WhoWeAre from "pages/Presentation/components/WhoWeAre";
import kharisLogo from "assets/images/kharislogos/kharis-icon-white.png";
const AOS = require("aos");

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation.jpg";
import bgMain from "assets/images/bg_main.jpeg";
import abstTech from "assets/images/ai_1_medium.jpeg";

function Presentation() {
    AOS.init();
    return (
        <>
            <DefaultNavbar
                routes={routes}
                // action={{
                //     type: "external",
                //     route: "https://www.creative-tim.com/product/material-kit-react",
                //     label: "free download",
                //     color: "info",
                // }}
                sticky
            />
            <MKBox
                minHeight="85vh"
                width="100%"
                py={20}
                sx={{
                    display: "grid",
                    placeItems: "center",
                    backgroundImage: ({
                        functions: { linearGradient, rgba },
                        palette: { gradients },
                    }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.5),
                            rgba(gradients.dark.state, 0.5)
                        )}, url(${bgMain})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                }}
                // sx={{
                //     backgroundImage: `url(${bgMain})`,
                //     backgroundSize: "cover",
                //     backgroundPosition: "top",
                //     display: "grid",

                //     placeItems: "center",
                // }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={7}
                        flexDirection="column"
                        justifyContent="center"
                        mx="auto"
                    >
                        <MKTypography
                            variant="h1"
                            color="white"
                            mt={-6}
                            textAlign="center"
                            mb={1}
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                            className="text-center"
                        >
                            {/* KharisDigital */}
                            <img style={{ width: "280px", height: "auto" }} src={kharisLogo} />
                        </MKTypography>
                        <MKTypography
                            variant="body1"
                            color="white"
                            textAlign="center"
                            px={{ xs: 6, lg: 12 }}
                            mt={1}
                        >
                            Smart Solutions for a Connected World 🌍
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
                        rgba(white.main, 0.8),
                    backdropFilter: "saturate(200%) blur(30px)",
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                {/* <Counters /> */}
                {/* <Information /> */}
                {/* <DesignBlocks />  */}
                {/* <Pages /> */}
                <Container id="who-we-are" sx={{ mt: 6 }}>
                    <WhoWeAre />
                </Container>
                <Container style={{ marginTop: "100px" }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={4}>
                            <FilledInfoCard
                                variant="gradient"
                                color="info"
                                icon="flag"
                                title="Innovative Solutions"
                                description="Our team of tech enthusiasts and visionaries is dedicated to developing solutions that go beyond the ordinary. We believe in pushing boundaries and turning ideas into transformative realities."
                                action={{
                                    type: "external",
                                    route: "https://www.creative-tim.com/learning-lab/react/overview/material-kit/",
                                    label: "Let's start",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FilledInfoCard
                                color="info"
                                icon="apps"
                                title="Client-Centric Approach"
                                description="At Kharis Digital Solutions, our clients are at the heart of everything we do. We collaborate closely to understand your unique challenges and deliver tailored tech solutions that drive success and exceed expectations."
                                action={{
                                    type: "external",
                                    route: "https://www.creative-tim.com/learning-lab/react/overview/datepicker/",
                                    label: "Read more",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FilledInfoCard
                                color="info"
                                icon="precision_manufacturing"
                                title="Expertise Across Industries"
                                description="Whether you're in healthcare, finance, manufacturing, or beyond, our tech expertise spans diverse industries. We leverage this knowledge to provide solutions that address specific industry challenges and drive sustainable growth."
                                action={{
                                    type: "external",
                                    route: "https://www.creative-tim.com/learning-lab/react/alerts/material-kit/",
                                    label: "Read more",
                                }}
                            />
                        </Grid>
                    </Grid>
                </Container>
                <CoreOfferings />
                {/* <Testimonials /> */}
                {/* <Download /> */}
                {/* <MKBox pt={18} pb={6}>
                    <Container>
                        <Grid container spacing={3}>
                            <Grid
                                item
                                xs={12}
                                lg={5}
                                ml="auto"
                                sx={{ textAlign: { xs: "center", lg: "left" } }}
                            >
                                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                                    Thank you for your support!
                                </MKTypography>
                                <MKTypography variant="body1" color="text">
                                    We deliver the best web products
                                </MKTypography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={5}
                                my={{ xs: 5, lg: "auto" }}
                                mr={{ xs: 0, lg: "auto" }}
                                sx={{ textAlign: { xs: "center", lg: "right" } }}
                            >
                                <MKSocialButton
                                    component="a"
                                    href="https://twitter.com/intent/tweet?text=Check%20Material%20Design%20System%20made%20by%20%40CreativeTim%20%23webdesign%20%23designsystem%20%23mui5&amp;url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fmaterial-kit-react"
                                    target="_blank"
                                    color="twitter"
                                    sx={{ mr: 1 }}
                                >
                                    <i className="fab fa-twitter" />
                                    &nbsp;Tweet
                                </MKSocialButton>
                                <MKSocialButton
                                    component="a"
                                    href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/material-kit-react"
                                    target="_blank"
                                    color="facebook"
                                    sx={{ mr: 1 }}
                                >
                                    <i className="fab fa-facebook" />
                                    &nbsp;Share
                                </MKSocialButton>
                                <MKSocialButton
                                    component="a"
                                    href="https://www.pinterest.com/pin/create/button/?url=https://www.creative-tim.com/product/material-kit-react"
                                    target="_blank"
                                    color="pinterest"
                                >
                                    <i className="fab fa-pinterest" />
                                    &nbsp;Pin it
                                </MKSocialButton>
                            </Grid>
                        </Grid>
                    </Container>
                </MKBox> */}
            </Card>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default Presentation;
