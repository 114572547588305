// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { OfferingsRightImage } from "../components/Offerings/offerings-right-image";
import { OfferingsLeftImage } from "../components/Offerings/offerings-left-image";
// Material Kit 2 React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// Images
import appleLogo from "assets/images/logos/gray-logos/logo-apple.svg";
import facebookLogo from "assets/images/logos/gray-logos/logo-facebook.svg";
import nasaLogo from "assets/images/logos/gray-logos/logo-nasa.svg";
import vodafoneLogo from "assets/images/logos/gray-logos/logo-vodafone.svg";
import digitalOceanLogo from "assets/images/logos/gray-logos/logo-digitalocean.svg";

import softwareDevAfrImg from "assets/images/home/software_dev_afr.jpeg";
import digitalTransformationImg from "assets/images/home/digitization.jpeg";
import eBankingImg from "assets/images/home/e-banking.jpeg";
import ticketingImg from "assets/images/home/ticketing.jpeg";
import projects from "assets/images/home/project-management.jpeg";
import contactcenter from "assets/images/home/software_development.jpeg";

const AOS = require("aos");

function CoreOfferings() {
    AOS.init();
    return (
        <MKBox component="section" py={12}>
            <Container>
                <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    justifyContent="center"
                    sx={{ mx: "auto", textAlign: "center" }}
                >
                    <MKTypography variant="h2">Our Core Offerings</MKTypography>
                    {/* <MKTypography variant="h2" color="info" textGradient mb={2}>
                        1,679,477+ web developers
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={2}>
                        Many Fortune 500 companies, startups, universities and governmental
                        institutions love Creative Tim&apos;s products.
                    </MKTypography> */}
                </Grid>

                {/* <Grid container spacing={3} sx={{ mt: 8, mb: 3 }}>
                    <Grid className="fore-img" item sm={12} md={6} lg={6}>
                        <img
                            data-aos="zoom-in"
                            data-aos-duration="300"
                            data-aos-once="true"
                            data-aos-delay="200"
                            className="business-type-images"
                            src={softwareDevAfrImg}
                            alt="baker holding croissants"
                        />
                    </Grid>
                    <Grid
                        className="d-flex flex-column justify-content-center align-items-start"
                        item
                        sm={12}
                        md={6}
                        lg={6}
                    >
                        <MKTypography variant="h2" sx={{ mb: 2 }}>
                            Software Development
                        </MKTypography>
                        <p
                            data-aos="fade-in"
                            data-aos-duration="900"
                            data-aos-once="true"
                            data-aos-delay="400"
                            className="w-480"
                        >
                            From scalable applications to bespoke software solutions, we bring your
                            ideas to life through agile and efficient development processes. <br />{" "}
                            <br /> We redefine user experience through seamlessly integrated mobile
                            and USSD applications. Our user-friendly mobile apps bring convenience
                            to your fingertips, offering a range of features from intuitive
                            navigation to secure transactions. Harness the power of USSD technology
                            for instant, accessible interactions, ensuring that even users without
                            smartphones can engage effortlessly. Whether you're managing finances,
                            accessing essential services, or enhancing communication, our mobile and
                            USSD apps are designed to empower users across diverse platforms.
                        </p>
                    </Grid>
                    <Grid className="post-img" item sm={12} md={6} lg={6}>
                        <img
                            data-aos="zoom-in"
                            data-aos-duration="300"
                            data-aos-once="true"
                            data-aos-delay="200"
                            className="business-type-images"
                            src={softwareDevAfrImg}
                            alt="baker holding croissants"
                        />
                    </Grid>
                </Grid> */}
                <OfferingsRightImage
                    image={contactcenter}
                    title={"Software Development"}
                    jsxText={
                        <span>
                            From scalable applications to bespoke software solutions, we bring your
                            ideas to life through agile and efficient development processes. <br />{" "}
                            <br /> We redefine user experience through seamlessly integrated mobile
                            and USSD applications. Our user-friendly mobile apps bring convenience
                            to your fingertips, offering a range of features from intuitive
                            navigation to secure transactions. Harness the power of USSD technology
                            for instant, accessible interactions, ensuring that even users without
                            smartphones can engage effortlessly. Whether you're managing finances,
                            accessing essential services, or enhancing communication, our mobile and
                            USSD apps are designed to empower users across diverse platforms.
                        </span>
                    }
                />

                {/* <Grid container spacing={3} sx={{ mt: 3, mb: 3 }}>
                    <Grid item sm={12} md={6} lg={6}>
                        <img
                            data-aos="zoom-in"
                            data-aos-duration="300"
                            data-aos-once="true"
                            data-aos-delay="200"
                            className="business-type-images"
                            src={assets.business_page_business_man_on_laptop}
                            alt=""
                        />
                    </Grid>
                    <Grid
                        item
                        className="d-flex flex-column justify-content-center align-items-start"
                        sm={12}
                        md={6}
                        lg={6}
                    >
                        <h2 className="mb-4 fw-bold w-480"> Digital Transformation</h2>
                        <p
                            data-aos="fade-left"
                            data-aos-duration="500"
                            data-aos-once="true"
                            data-aos-delay="300"
                            className="w-480"
                        >
                            E-Business is a custom made solution ready to be tailored for
                            organizations that need real time online services for their customers.{" "}
                            <br /> <br /> It is very beneficial for organizations who care much
                            about their customers and are happy to provide them convenience.
                        </p>
                        <div className="d-flex justify-content-start align-items-center">
                            <Link to="/business/e-business" className="me-2">
                                <ActionButton>Get Started</ActionButton>
                            </Link>
                        </div>
                    </Grid>
                </Grid> */}
                <OfferingsLeftImage
                    image={digitalTransformationImg}
                    title={"Digital Transformation"}
                    jsxText={
                        "Embrace the future with confidence. We guide businesses through seamless digital transformations, optimizing processes and enhancing overall efficiency."
                    }
                />

                <OfferingsRightImage
                    image={ticketingImg}
                    title={"Ticketing"}
                    jsxText={
                        <span>
                            Are your premier destination for cutting-edge ticketing solutions that
                            redefine event management. We pride ourselves on delivering a
                            comprehensive suite of services designed to streamline your ticketing
                            processes and elevate the overall event experience. Our intuitive and
                            customizable ticketing platform empowers event organizers with seamless
                            solutions for ticket sales, event promotion, and attendee engagement.{" "}
                            <br /> <br /> Discover the freedom of hassle-free event management with
                            our user-friendly interface, robust analytics, and secure payment
                            processing. Whether you're hosting a concert, conference, or sports
                            event, KDS provides the tools to effortlessly create, manage, and
                            optimize your ticketing strategy. Join a community of event
                            professionals who trust KDS to bring their events to life, one ticket at
                            a time. Welcome to a new era in ticketing solutions – where efficiency
                            meets innovation, and your events thrive.
                        </span>
                    }
                    link={"Learn More"}
                />

                <OfferingsLeftImage
                    image={eBankingImg}
                    title={"E-Banking Platform"}
                    jsxText={
                        "We redefine the banking experience, offering a seamless fusion of advanced technology and financial expertise. Manage your finances effortlessly with our intuitive user interface, allowing you to check balances, transfer funds, and pay bills with just a few clicks. Our robust security measures ensure your transactions are safeguarded at every step. Whether you're on the go or at home, our responsive design ensures a consistent and user-friendly experience across devices"
                    }
                />

                <OfferingsRightImage
                    image={softwareDevAfrImg}
                    title={"Call Center and Support Services"}
                    jsxText={`Elevate your customer experience as our dedicated team of skilled professionals handles inquiries, resolves issues, and provides exceptional support on your behalf. Our cutting-edge technology ensures seamless integration, offering a scalable solution for businesses of all sizes. Whether you need 24/7 customer support, technical assistance, or multichannel communication, we've got you covered. Focus on your core business while we deliver personalized, efficient, and top-notch support to your valued customers.`}
                />

                <OfferingsLeftImage
                    image={projects}
                    title={"Project Management and consultancy "}
                    jsxText={
                        "Our seasoned team of professionals are dedicated to navigating the complexities of your projects, ensuring seamless execution and optimal outcomes. From conception to delivery, we offer comprehensive project management services tailored to your industry's specific needs. Beyond project execution, our consultancy services provide strategic insights and innovative solutions to propel your business forward. With a commitment to excellence, We are your trusted partner, empowering you to achieve success in every endeavor"
                    }
                />
            </Container>
        </MKBox>
    );
}

export default CoreOfferings;
