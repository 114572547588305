import React from "react";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import "./offerings.css";
import Link from "assets/theme/components/link";

const AOS = require("aos");

export const OfferingsRightImage = ({ image, title, jsxText, link }) => {
    AOS.init();
    return (
        <Grid container spacing={3} sx={{ mt: 8, mb: 3 }}>
            {/* <Grid className="fore-img" item sm={12} md={6} lg={6}>
                <img
                    data-aos="zoom-in"
                    data-aos-duration="300"
                    data-aos-once="true"
                    data-aos-delay="200"
                    className="business-type-images"
                    src={image}
                    alt="baker holding croissants"
                />
            </Grid> */}
            <Grid
                className="d-flex flex-column justify-content-center align-items-start"
                item
                sm={12}
                md={6}
                lg={6}
            >
                <MKTypography variant="h2" sx={{ mb: 2 }}>
                    {title}
                </MKTypography>
                <p
                    style={{ fontSize: "16px" }}
                    data-aos="fade-in"
                    data-aos-duration="900"
                    data-aos-once="true"
                    data-aos-delay="400"
                    className="w-480"
                >
                    {jsxText}
                </p>
                {link && (
                    <a style={{ fontSize: "16px" }} href="#">
                        {link}
                    </a>
                )}
            </Grid>
            <Grid className="post-img" item sm={12} md={6} lg={6}>
                <img
                    data-aos="zoom-in"
                    data-aos-duration="300"
                    data-aos-once="true"
                    data-aos-delay="200"
                    className="business-type-images"
                    style={{ height: "auto" }}
                    src={image}
                    alt="baker holding croissants"
                />
            </Grid>
        </Grid>
    );
};
