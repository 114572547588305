/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import smilingMan from "assets/images/corporate-smiling.jpeg";

function Information() {
    return (
        <MKBox component="section" py={12}>
            <Container>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} lg={6}>
                        <p style={{ fontSize: "16px" }}>
                            Join our team and be part of a dynamic and inclusive work environment
                            that empowers individuals to thrive and innovate. Our dedicated
                            professionals share a commitment to excellence, and as we grow, we
                            actively seek talented and motivated individuals to contribute their
                            unique skills.
                        </p>

                        <p style={{ fontSize: "16px" }}>
                            Explore exciting career paths, engage in ongoing professional
                            development, and become part of a collaborative community that values
                            creativity. If you're ready for a rewarding journey where your
                            aspirations are valued and your potential is realized, check out our
                            current openings and take the first step towards a fulfilling career
                            with us
                        </p>
                    </Grid>
                    <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
                        <CenteredBlogCard
                            image={smilingMan}
                            title=""
                            description="Click here to view jobs"
                            action={{
                                type: "internal",
                                route: "pages/company/about-us",
                                color: "info",
                                label: "View Jobs",
                            }}
                        />
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default Information;
