import React from "react";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import "./offerings.css";

const AOS = require("aos");

export const OfferingsLeftImage = ({ image, title, jsxText }) => {
    AOS.init();
    return (
        <Grid container spacing={3} sx={{ mt: 6, mb: 6 }}>
            <Grid item sm={12} md={6} lg={6}>
                <img
                    data-aos="zoom-in"
                    data-aos-duration="300"
                    data-aos-once="true"
                    data-aos-delay="200"
                    className="business-type-images"
                    src={image}
                    alt=""
                />
            </Grid>
            <Grid
                item
                className="d-flex flex-column justify-content-center align-items-start"
                sm={12}
                md={6}
                lg={6}
            >
                <h2 className="mb-4 fw-bold w-480"> {title}</h2>
                <p
                    style={{ fontSize: "16px" }}
                    data-aos="fade-left"
                    data-aos-duration="500"
                    data-aos-once="true"
                    data-aos-delay="300"
                    className="w-480"
                >
                    {jsxText}
                </p>
            </Grid>
        </Grid>
    );
};
